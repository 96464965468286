import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Global } from "@emotion/react"
import theme from "../gatsby-plugin-theme-ui"
import { Themed } from "theme-ui"
import { Header, Hero, Section, ProjectItem } from "../components"
import { SectionWrap, ProjectList } from "../components/styles"
import { globalStyles } from "../templates/styles"
import { StaticImage } from "gatsby-plugin-image"
import { Modal } from "react-responsive-modal"
import "react-responsive-modal/styles.css"
import Seo from "../components/seo"
import AppStores from "../components/AppStores.js"
import SearchBox from "../components/SeachBox.js"
var allProjects = [
  {
    name: "Der, Die & Das Rules",
    link: "/articles/der-die-das-gender-rules",
    alt: "Project One",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../images/icon.png"
        alt="der,die,das rules"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "English",
  },
]

var allShowcases = [
  {
    name: "Learn More",
    link: "/showcase",
    alt: "Learn more link",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../images/icon.png"
        alt="Der Die Das Icon"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "English",
  },
  ...allProjects,
  {
    name: "Browse Nouns",
    link: "/browse",
    alt: "Browse nouns link",
    image: (
      <StaticImage
        style={{ width: "100%", height: "100%" }}
        src="../images/letters.jpg"
        alt="Letters picture"
        placeholder="blurred"
        layout="fixed"
        objectFit={"cover"}
        width={400}
        height={400}
      />
    ),
    badge: "English",
  },
]

const HomeTemplate = () => {
  const [open, setOpen] = React.useState(false)
  const [emailAddress, setEmailAddress] = React.useState("no-spam@spam.com")

  const onOpenModal = e => {
    e.preventDefault()
    setOpen(true)
  }
  const onCloseModal = () => setOpen(false)
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteTitle
          siteTitleShort
          siteAuthor
          siteLogoText
          siteUrl
          siteLanguage
          siteDescription
          siteKeywords
        }
      }
      dataJson {
        hero {
          imageAlt
        }
        work {
          content
          heading
        }
        about {
          heading
          content
        }
      }
    }
  `)
  const projectsItems = allProjects
  const { hero } = data.dataJson

  return (
    <div>
      <Seo
        title={"Der Die Das Train - Der Die Das: German Learning"}
        description={
          "Der Die Das Train is a game of German nouns and their articles (der, die, and das) and will help you learn and memorize which article goes with which noun."
        }
        lang={"en"}
        keywords={
          "Der Die Das, Train, Reference, Word, Games,  Articles, Grammar, Learn, German, Nouns, ios, android, app"
        }
      />
      <Global styles={globalStyles} />
      <Header logoTxt={"Der Die Das Train"} />
      <SectionWrap>
        <Hero imageAlt={hero.imageAlt} id="hero" />
        <SearchBox></SearchBox>

        <div
          style={{
            display: "flex",
            width: "100%",
            backgroundColor: "white",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "1em",
            paddingVertical: "2em",
          }}
        >
          <StaticImage
            src="../images/mirror/1.png"
            className="showcase-img"
            alt="phone mockup"
            placeholder={"blurred"}
            fit={"contain"}
            height={750}
          />
          <StaticImage
            src="../images/mirror/en/2.png"
            className="showcase-img"
            alt="phone mockup"
            placeholder={"blurred"}
            fit={"contain"}
            height={750}
          />
          <div
            style={{
              display: "flex",
              width: "100%",

              justifyContent: "center",
              marginBottom: "30%",
              padding: "1em",
              position: "absolute",
              zIndex: 1,
            }}
          >
            <AppStores></AppStores>
          </div>
        </div>

        {true === "true" ? (
          <Section backgroundColor={theme.colors.white} style={{}} id="work">
            <Themed.h2>{"The Rules"}</Themed.h2>
            <Themed.p>{"Learn the basic rules of genders"}</Themed.p>
            {projectsItems && (
              <ProjectList>
                {projectsItems.map(
                  ({ name, link, alt, badge, image }, index) => (
                    <ProjectItem
                      key={name + index}
                      image={image}
                      alt={alt}
                      name={name}
                      badge={badge}
                      link={link}
                    />
                  )
                )}
              </ProjectList>
            )}
          </Section>
        ) : null}

        <Section
          backgroundColor={theme.colors.white}
          id="work"
          containerId="section-inner-container"
        >
          <div className={"body-container"}>
            <Themed.h2>{"Game Your Way to German Mastery!"}</Themed.h2>
            <Themed.p>
              <span style={{ fontWeight: "bold" }}>Der Die Das Train</span>
              {
                " is an engaging mobile app designed to help users master German nouns and their corresponding articles ('der', 'die', and 'das'). Available on both the App Store for iOS and Google Play for Android, this interactive game offers a fun and effective way to learn German grammar, tackling one of the hardest parts for German learners."
              }
            </Themed.p>
          </div>
          {allShowcases && (
            <ProjectList>
              {[...allShowcases].map(
                ({ name, link, alt, badge, image }, index) => (
                  <ProjectItem
                    key={name + index}
                    image={image}
                    alt={alt}
                    name={name}
                    badge={badge}
                    link={link}
                  />
                )
              )}
            </ProjectList>
          )}
        </Section>
        <Section
          backgroundColor={theme.colors.secondary}
          id="about"
          light
          enableWave={true}
          waveBottom={false}
        >
          <div
            class="icon-bar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
              marginTop: "10rem",
            }}
          >
            <div
              style={{
                maxWidth: "500px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="icon-container">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.instagram.com/der.die.das.train/"
                  class="fa fa-instagram"
                  title={"Vist Our Instagram"}
                >
                  <StaticImage
                    src="../images/instagram-brands.svg"
                    alt={"Instagram Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Instagram</p>
              </div>
              <div className="icon-container">
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.facebook.com/Der.Die.Das.Train"
                  class="fa fa-facebook"
                  title={"Vist Our Facebook"}
                >
                  <StaticImage
                    src="../images/facebook-f-brand.svg"
                    alt={"Facebook Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Facebook</p>
              </div>
            </div>
          </div>
          <div
            class="icon-bar"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          >
            <div
              style={{
                maxWidth: "500px",
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <div className="icon-container">
                <a
                  href="https://der-die-das-train.com/terms-and-conditions"
                  class="fa fa-instagram"
                  title={"Terms and Conditions"}
                >
                  <StaticImage
                    src="../images/balance-scale-right-solid.svg"
                    alt={"Terms and Conditions Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>T & Cs</p>
              </div>
              <div className="icon-container">
                <a
                  href="https://der-die-das-train.com/privacy-policy"
                  class="fa fa-facebook"
                  title={"Privacy Policy"}
                >
                  <StaticImage
                    src="../images/user-lock-solid.svg"
                    alt={"Privacy Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Privacy Policy</p>
              </div>
              <div className="icon-container">
                <a
                  href="http://der-die-das-train.com"
                  class="fa fa-facebook"
                  title={"Contact"}
                  onClick={e => {
                    e.preventDefault()
                    setEmailAddress("support@der-die-das-train.com")

                    onOpenModal(e)
                  }}
                >
                  <StaticImage
                    src="../images/envelope-open-regular.svg"
                    alt={"Contact Icon"}
                    style={{ color: "#f0d723" }}
                    placeholder={"blurred"}
                    fit={"contain"}
                    height={50}
                  />
                </a>
                <p>Contact</p>
              </div>
            </div>
          </div>
          <div>
            <Modal open={open} onClose={onCloseModal} center>
              <div>
                <h3>Contact</h3>
                <p id={"email-address"}>{emailAddress}</p>
              </div>
            </Modal>
          </div>
        </Section>
      </SectionWrap>
    </div>
  )
}

export default HomeTemplate
